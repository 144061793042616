<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="blog-dteails-content blog-border">
            <div class="blog-details-top">
                <h1 class="title" style="color: rgb(11, 20, 156)">
                    Cuentas generales del Ayuntamiento. Las irregularidades que no cesan…
                </h1>
                <ul>
                    <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
                    <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
                    <li><i class="fal fa-calendar-alt"></i> 16 octubre 2021</li>
                </ul>
            </div>





            <body lang=EN-US style='word-wrap:break-word'>

                <div class=WordSection1>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><b><span lang=es>Autobuses Urbanos y Accesibilidad en Jerez</span></b></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><b><span lang=es>¿Ignorancia, insensibilidad o incumplimiento ante la
                                discapacidad?</span></b></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><b><span lang=es>Cumplir la ley</span></b></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>Es sorprendente que tras 15 años
                            desde que se promulgó el Real Decreto 1544/2007, por el que se regulan las <b>condiciones
                                básicas de accesibilidad</b> y no discriminación para el acceso y utilización
                            de los modos de transporte para personas con discapacidad, no se hayan
                            acondicionado las paradas de la red de autobuses urbano en la ciudad.</span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>Las marquesinas siguen
                            igualmente pendientes de  acondicionarse para las personas con dificultades de
                            visión, y la gran mayoría no son más que un poste vertical.</span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>Esto supone antes que nada, un
                            incumplimiento continuado del propio Plan General de Ordenación Urbana de
                            Jerez. Una modificación que se aprobó en el Pleno Municipal, que sólo ha
                            servido para obtener un titular en la prensa local.</span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>Aún es más grave que las que se
                            han “levantado” en los últimos tiempos no tengan en cuenta los preceptos que la
                            ley establece, máxime cuando se realizan con financiación europea (EDUSI), como
                            es el caso de las habilitadas tras la reestructuración de la calle Corredera,
                            junto a Plaza de las Angustias y en la Plaza Esteve, en las que la infracción
                            por <b>incumplimiento legal </b>pudiera conllevar amonestación y pago de
                            sanción. </span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><b><span lang=es style='font-size:10.0pt'>Más ambición</span></b></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>En el marco de la accesibilidad,
                            y si se pretende ser inclusivo en un sentido más avanzado que lo exigido por la
                            legislación, deberían contemplarse otras necesidades personales temporales. </span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>Cuando viajas en autobús, se
                            observan las dificultades de subir a padres con carritos para bebés, personas
                            mayores con su andador y otros con carros de la compra; en paradas mal acondicionadas,
                            de aceras tortuosas o cuando éstas quedan distantes al vehículo, que suele
                            pasar a menudo. </span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><b><span lang=es style='font-size:10.0pt'>Más transparencia</span></b><span lang=es
                            style='font-size:10.0pt'>,</span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>Por otro lado, una mejora en la
                            transparencia del servicio, sería de agradecer por la ciudadanía.</span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>Nos gustaría saber cuántos
                            autobuses por su antigüedad y por su incapacidad de diseño no son accesibles
                            para <b>personas con dificultad motriz, </b>¿será difícil saberlo?, en ningún
                            lugar figura y los autobuses van cambiando de línea por día y por horas.</span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>Es complicado simplemente saber
                            cuántos están en funcionamiento real, puesto que según “dicen las malas
                            lenguas”, parte de la flota ya no existe, está descompuesta, para reutilizar
                            sus piezas en otras unidades y evitar adquirir repuestos nuevos, que ya se sabe
                            cómo vamos de tesorería municipal.</span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>&nbsp;</span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>Es bastante extraño que algunos
                            de los autobuses que circulan al día de hoy por Jerez reúnan o <b>cumplan las
                                mínimas condiciones de seguridad</b> requeridas para su uso, <span
                                style='background:white'>la gente al comprobar el estado de las ruedas, junto
                                con los humos </span>y el ruido atronador que <span style='background:white'>salen
                                de esas cafeteras rodantes</span>, <span style='background:white'>se preguntará
                                cómo pueden pasar cada año la ITV y si al usarlos no estarán asumiendo un
                                riesgo personal innecesario, porque el sentido común les pone en guardia antes
                                de subir.</span></span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>Jerez, 21 de Enero de 2023</span></p>

                    <p class=MsoNormal style='margin-top:12.0pt;text-align:justify;line-height:
            normal'><span lang=es style='font-size:10.0pt'>&nbsp;</span></p>

                    <p class=MsoNormal><span lang=es>&nbsp;</span></p>

                </div>

            </body>




            <br>
            <br />
            Observatorio Ciudadano Municipal de Jerez (OCM Jerez)
        </div>
    </div>
</div>


<app-footer></app-footer>