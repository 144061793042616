import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-autobuses-accesibilidad',
  templateUrl: './autobuses-accesibilidad.component.html',
  styleUrls: ['./autobuses-accesibilidad.component.css']
})
export class AutobusesAccesibilidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
