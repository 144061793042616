<app-header></app-header>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 id="demotext">Blog</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/' style="color: white">Inicio </a></li>

                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->

<!--====== BLOG STANDARD PART START ======-->
<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="blog-standard">
                    <div class="single-blog-standard mt-30">
                        <!-- <div class="blog-standard-thumb">
                            <img src="assets/images/blog/entrada1/costeServiciosObligatorios.png" alt="blog">
                        </div> -->


                        <!-- Inicio entrada  -->
                        <div class="blog-standard-content">
                            <h2 class="title">
                                Autobuses Urbanos y Accesibilidad en Jerez
                                <br>
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">26 enero 2023</span>
                                <span style="color: red; font-weight: bold">&nbsp;&nbsp;Nueva entrada&nbsp;</span>
                                <i class="fal fa-exclamation-circle fa-2x"></i>
                                <br />
                            </p>
                            <p style="
text-align: justify;
color: black;
padding-top: 4px;
padding-bottom: 10px;
">
                                ¿Ignorancia, insensibilidad o incumplimiento ante la discapacidad?

                            </p>
                            <a class="main-btn" routerLink="/AutobusesAccesibilidad">Leer más... <i
                                    class="fal fa-long-arrow-right"></i></a>
                        </div>
                        <!-- Final entrada  -->


                        <!-- Inicio entrada  -->
                        <div class="blog-standard-content">
                            <h2 class="title">
                                EL TSUNAMI (ECONÓMICO) LLEGARÁ A JEREZ EN 2024…
                                <br>
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">19 marzo 2022</span>
                                <!-- <span style="color: red; font-weight: bold">&nbsp;&nbsp;Nueva entrada&nbsp;</span> -->
                                <!-- <i class="fal fa-exclamation-circle fa-2x"></i> -->
                                <br />
                            </p>
                            <p style="
text-align: justify;
color: black;
padding-top: 4px;
padding-bottom: 10px;
">
                                No sé si tendrá razón un buen amigo, que me dijo que “Jerez es como Mónaco, pero en
                                tieso”… Lo que sí sé es que los jerezanos recordaremos en 2024 a los que siguen
                                alimentando el tsunami económico que se nos viene encima…
                                <br>
                                <br>
                                Ahora que ya he conseguido atraer su atención, les explicaré qué quiero decir.
                                El próximo lunes 21, el pleno del ayuntamiento de Jerez votará la aprobación de un nuevo
                                préstamo de 7 millones de euros con Hacienda, tras lo que nuestra deuda financiera
                                seguirá creciendo, ya muy por encima de los 900 millones de euros (la deuda de Emuvijesa
                                también hay que contarla, Delegada, y la mayoría la tenemos con Hacienda, no con los
                                bancos…).

                            </p>
                            <a class="main-btn" routerLink="/TsunamiEconomico">Leer más... <i
                                    class="fal fa-long-arrow-right"></i></a>
                        </div>
                        <!-- Final entrada  -->


                        <!-- Inicio entrada  -->
                        <div class="blog-standard-content">
                            <h2 class="title">
                                Motivos para la preocupación (otra vez...) con las cuentas del Ayuntamiento
                                <br>
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">8 marzo 2022</span>
                                <br />
                            </p>
                            <p style="
        text-align: justify;
        color: black;
        padding-top: 4px;
        padding-bottom: 10px;
        ">
                                Hace pocos días nuestro Ayuntamiento ha publicado los que podemos considerar como
                                primeros datos de la liquidación del presupuesto de 2021. Y la información es muy
                                preocupante. Si no me creen, lean lo que les cuento.
                                <br>
                                <br>
                                Antes de nada, no se vayan ustedes a equivocar. La publicación que ha hecho el
                                Ayuntamiento no se ha debido a que nuestra Alcaldesa y Delegada de Hacienda hayan sido
                                iluminadas por la “luz de la transparencia”. Ni mucho menos. Por desgracia las dos
                                siguen siendo firmes defensoras de incumplir la ley cuando de dar cuentas de su gestión
                                económica se trata, tanto en el contenido de la información como en los plazos de
                                publicación.

                            </p>
                            <a class="main-btn" routerLink="/MotivosPreocupacion">Leer más... <i
                                    class="fal fa-long-arrow-right"></i></a>
                        </div>
                        <!-- Final entrada  -->

                        <!-- Inicio entrada  -->
                        <div class="blog-standard-content">
                            <h2 class="title">
                                A quién le importa
                                <br>
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">7 febrero 2022</span>
                                <br />
                            </p>
                            <p style="
        text-align: justify;
        color: black;
        padding-top: 4px;
        padding-bottom: 10px;
        ">
                                Hace casi cuarenta años que la cantante Alaska, junto con el grupo Dinarama, hizo
                                tremendamente popular la canción “A quién le importa”. Recomiendo a todos aquellos que
                                son tan insultantemente jóvenes como para no recordar esta canción que la busquen el
                                Youtube…
                                <br>
                                <br>
                                Eso es lo que, por desgracia, tenemos que preguntarnos cada día que pasa en Jerez sin
                                que se presente el presupuesto de 2022, del que ya llevamos un mes consumido, y sin que
                                parezca que vayamos a tener respuesta durante las próximas semanas. Y les recuerdo que,
                                una vez aprobado aquí, el presupuesto tendrá que ser aprobado por el Ministerio de
                                Hacienda, por lo que tendremos suerte si estos presupuestos llegan a estar en vigor algo
                                más de seis meses (a mí no me sorprendería que nos pasáramos otro año sin presupuesto)…

                            </p>
                            <a class="main-btn" routerLink="/AQuienLeImporta">Leer más... <i
                                    class="fal fa-long-arrow-right"></i></a>
                        </div>
                        <!-- Final entrada  -->

                        <!-- Inicio entrada  -->
                        <div class="blog-standard-content">
                            <h2 class="title">
                                Descontrol presupuestario.
                                <br>
                                Como gobernar sin rigor presupuestario alguno, o mejor aún, sin presupuesto.
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">2 diciembre 2021</span>
                                <br />
                            </p>
                            <p style="
        text-align: justify;
        color: black;
        padding-top: 4px;
        padding-bottom: 10px;
        ">
                                El Real Decreto Legislativo 2/2004, de 5 de marzo, por el que se aprueba el texto
                                refundido de la Ley Reguladora de las Haciendas Locales, define los presupuesto en su
                                artículo 162:
                                Artículo 162. Definición.
                                Los presupuestos generales de las entidades locales constituyen la expresión cifrada,
                                conjunta y sistemática de las obligaciones que, como máximo, pueden reconocer la
                                entidad, y sus organismos autónomos, y de los derechos que prevean liquidar durante el
                                correspondiente ejercicio, así como de las previsiones de ingresos y gastos de las
                                sociedades mercantiles cuyo capital social pertenezca íntegramente a la entidad local
                                correspondiente.
                                <br>
                                <br />
                                En definitiva, los presupuestos son el documento en el que se recoge la previsión anual
                                de los ingresos y gastos del Ayuntamiento y constituyen uno de los instrumentos más
                                importantes de la política económica del Gobierno Local, en los que se plasman los
                                objetivos estratégicos de las distintas políticas públicas y los recursos asignados para
                                su cumplimiento.
                            </p>
                            <a class="main-btn" routerLink="/DescontrolPresupuestario">Leer más... <i
                                    class="fal fa-long-arrow-right"></i></a>
                        </div>
                        <!-- Final entrada  -->

                        <!-- Inicio entrada  -->
                        <div class="blog-standard-content">
                            <h2 class="title">
                                JEREZ SE VENDE POR SÓLO 14.999 EUROS…
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">30 noviembre 2021</span>

                                <br />
                            </p>
                            <p style="
                                text-align: justify;
                                color: black;
                                padding-top: 4px;
                                padding-bottom: 10px;
                                ">
                                Imagine por un momento que tuviera usted un par de hijos.
                                <br>
                                <br />
                                Estará usted de acuerdo conmigo en que, el día uno de enero de cada año, usted tendría
                                muy claro que sus hijos iban a tener la buena y sana costumbre de comer todos y cada uno
                                de los días del año. Y usted contaría con que tendría que gastar en su alimentación
                                durante todo el año.
                                <br>
                                Todos podemos estar de acuerdo con la anterior afirmación. Pero en Jerez las cosas son
                                distintas… <br>
                                <br />
                                Y es que en Jerez tenemos una Alcaldesa que tiene la responsabilidad de los animales del
                                Zoológico que, a diferencia de lo que le pasa a los hijos de mi anterior ejemplo,
                                pareciera que sólo tienen necesidad de comer durante los próximos tres meses…
                                Pero ahora dejemos las bromas e ironías aparte, porque la cosa no tiene ninguna gracia.
                                Se lo explicaré con breves preguntas y respuestas.
                            </p>
                            <a class="main-btn" routerLink="/Jerez14999">Leer más... <i
                                    class="fal fa-long-arrow-right"></i></a>
                        </div>
                        <!-- Final entrada  -->


                        <!-- Inicio entrada  -->
                        <div class="blog-standard-content">
                            <h2 class="title">
                                JEREZ NO (les) INTERESA…
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">12 noviembre 2021</span>

                                <br />
                            </p>
                            <p style="
                                text-align: justify;
                                color: black;
                                padding-top: 4px;
                                padding-bottom: 10px;
                               ">
                                No se equivoque: JEREZ SÍ INTERESA…
                                La Feria, la Semana Santa, las zambombas, el flamenco, los vinos, los caballos, las
                                motos… Jerez interesa y atrae con sus encantos a cientos de miles de personas cada año.
                                A toda esa gente claro que les interesa.
                                <br>
                                De quienes les hablo es de otros. De los gobernantes de los distintos niveles de la
                                administración que, con sus hechos, parecen demostrar que no tienen demasiado interés
                                por el futuro de Jerez.
                                ¿No me creen? Déjenme que les cuente.


                            </p>
                            <a class="main-btn" routerLink="/JerezNoLesInteresa">Leer más... <i
                                    class="fal fa-long-arrow-right"></i></a>
                        </div>
                        <!-- Final entrada  -->

                        <!-- Inicio entrada  -->
                        <div class="blog-standard-content">
                            <h2 class="title">
                                Cuentas generales del Ayuntamiento. Las irregularidades que no cesan…
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">16 octubre 2021</span>
                                <br />
                            </p>
                            <p style="
                                text-align: justify;
                                 color: black;
                                 padding-top: 4px;
                                 padding-bottom: 10px;
                                 ">
                                Imagine por un momento que vuelve a ser un niño en edad escolar…
                                Imagine que, año tras año, suspende siempre la misma asignatura…
                                Imagine que el examen es siempre el mismo, y que usted se equivoca en las mismas
                                preguntas, dando las mismas respuestas erróneas una y otra vez…

                            </p>
                            <a class="main-btn" routerLink="/cuentasGenerales20192020">Leer más... <i
                                    class="fal fa-long-arrow-right"></i></a>
                        </div>
                        <!-- Final entrada  -->

                        <!-- Inicio entrada  -->
                        <div class="blog-standard-content">
                            <h2 class="title">
                                A vueltas con Comujesa y los autobuses…
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">23 septiembre 2021</span>
                                <!-- <span style="color: red; font-weight: bold">&nbsp;&nbsp;Nueva entrada&nbsp;</span>
                                <i class="fal fa-exclamation-circle fa-2x"></i> -->
                                <br />
                            </p>
                            <p style="
                              text-align: justify;
                              color: black;
                              padding-top: 4px;
                              padding-bottom: 10px;
                            ">
                                En julio de 2020 nuestro Ayuntamiento anunció su intención de trasladar parte de los
                                servicios prestados por Comujesa a la parcela de la Ciudad del Transporte que en su
                                día iba a ocupar el fallido proyecto de Zahav. En concreto, el servicio de autobuses y
                                el de calas y alumbrado público.
                            </p>
                            <a class="main-btn" routerLink="/comujesa202109">Leer más... <i
                                    class="fal fa-long-arrow-right"></i></a>
                        </div>
                        <!-- Final entrada  -->


                        <div class="blog-standard-content">
                            <h2 class="title">Lo que se ve, y lo que no se ve……
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">10 septiembre 2021</span>

                                <br />
                                <!-- <span style="color: black; font-weight: bold"><i class="fal fa-eye"></i> 32
                                    vistas&nbsp; &nbsp;&nbsp;</span>
                                <span style="color: black; font-weight: bold"><i class="fal fa-comments"></i> 0
                                    Comentarios</span> -->
                            </p>

                            <p style="
                              text-align: justify;
                              color: black;
                              padding-top: 4px;
                              padding-bottom: 10px;
                            ">
                                Es probable que dentro de unos días el pleno del Ayuntamiento de Jerez apruebe la
                                solicitud
                                para 2022 de un nuevo préstamo de 9 millones de euros, para el pago de sentencias a
                                pagar a
                                antiguos proveedores, con la posibilidad de que el préstamo total aumente hasta los 19
                                millones de
                                euros.
                            </p>

                            <div class="blog-flex">
                                <!-- <div class="blog-left">
                                    <p><span>por</span> OCM</p>
                                    <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                </div> -->
                                <div class="blog-right">
                                    <a routerLink='/loQueSeVe'>Leer más... <i class="fal fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>


                        <div class="blog-standard-content">
                            <h2 class="title"> Lo que no te han contado del presupuesto…
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">17 agosto 2021</span>
                                <br />
                                <!-- <span style="color: black; font-weight: bold"><i class="fal fa-eye"></i> 32
                                    vistas&nbsp; &nbsp;&nbsp;</span>
                                <span style="color: black; font-weight: bold"><i class="fal fa-comments"></i> 0
                                    Comentarios</span> -->
                            </p>

                            <p style="
                              text-align: justify;
                              color: black;
                              padding-top: 4px;
                              padding-bottom: 10px;
                            ">
                                Hace ya más de dos semanas que la Delegada de Economía del Ayuntamiento de Jerez, Laura
                                Álvarez, compareció ante los medios para informar del, a su juicio, excelente resultado
                                de la liquidación
                                del presupuesto de 2020: 30 millones euros de superávit presupuestario, mejora de 11
                                millones del excedente
                                de tesorería, reducción de la deuda con proveedores…
                            </p>

                            <div class="blog-flex">
                                <!-- <div class="blog-left">
                                    <p><span>por</span> OCM</p>
                                    <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                </div> -->
                                <div class="blog-right">
                                    <a routerLink='/blogentrada3'>Leer más... <i
                                            class="fal fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="single-blog-standard mt-30">
                        <!-- <div class="blog-standard-thumb">
                            <img src="assets/images/blog/entrada1/costeServiciosObligatorios.png" alt="blog">
                        </div> -->
                        <div class="blog-standard-content">
                            <h2 class="title"> Coste efectivo de los servicios prestados por el Ayuntamiento año 2019.
                            </h2>
                            <p>
                                <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                <span>por</span> OCM
                                <span style="color: rgb(37, 7, 87)">20 diciembre 2020</span>
                                <br />
                                <!-- <span style="color: black; font-weight: bold"><i class="fal fa-eye"></i> 232
                                    vistas&nbsp; &nbsp;&nbsp;</span>
                                <span style="color: black; font-weight: bold"><i class="fal fa-comments"></i> 0
                                    Comentarios</span> -->
                            </p>

                            <!-- <ul>
                                <li><i class="fal fa-eye"></i> 232 Vistas</li>
                                <li><i class="fal fa-comments"></i> 0 Comentarios</li>
                                <li><i class="fal fa-calendar-alt"></i>20 diciembre 2020</li>
                            </ul> -->

                            <p style="
                              text-align: justify;
                              color: black;
                              padding-top: 4px;
                              padding-bottom: 10px;
                            ">
                                Todos los datos se han obtenido del informe emitido por el servicio económico-financiero
                                del Ayuntamiento de Jerez
                                <br />
                                Para el cálculo del coste efectivo de los servicios, el Ministerio
                                de Hacienda y Función Pública ha desarrollado la
                                <a href="https://www.boe.es/eli/es/o/2014/11/06/hap2075" target="_blank">Orden
                                    HAP/2075/2014 de 6 de noviembre</a>
                                por la que se establecen los criterios de cálculo del coste efectivo
                                de los servicios prestados por las entidades locales. La citada
                                Orden considera que los costes deben estar integrados por costes
                                reales directos e indirectos que se deriven de la ejecución de
                                gastos. <br />
                            </p>

                            <div class="blog-flex">
                                <!-- <div class="blog-left">
                                    <p><span>por</span> OCM</p>
                                    <img style="width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt="">
                                </div> -->
                                <div class="blog-right">
                                    <a routerLink='/blogentrada1'>Leer más... <i
                                            class="fal fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--
                    <div class="single-blog-standard mt-30">
                        <div class="blog-standard-thumb">
                            <img src="assets/images/blog-standard-3.jpg" alt="blog">
                            <a class="video-popup" href="http://bit.ly/2VuPnrx"><i class="fas fa-play"></i></a>
                        </div>
                        <div class="blog-standard-content">
                            <span>Businese</span>
                            <h2 class="title">Adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.</h2>
                            <ul>
                                <li><i class="fal fa-eye"></i> 232 Views</li>
                                <li><i class="fal fa-comments"></i> 35 Comments</li>
                                <li><i class="fal fa-calendar-alt"></i> 24th March 2019</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
                            <div class="blog-flex">
                                <div class="blog-left">
                                    <p><span>by</span> Hetmayar</p>
                                    <img src="assets/images/blog-girl.png" alt="">
                                </div>
                                <div class="blog-right">
                                    <a routerLink='/blog-detail'><i class="fal fa-arrow-right"></i>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div> -->

                </div>

                <!-- <div class="row">
                    <div class="col-lg-12">
                        <nav class="mt-60" aria-label="Page navigation example">
                            <ul class="pagination pagination-2 justify-content-center">
                                <li class="page-item">
                                    <a class="page-link d-none d-sm-inline-block" href="#" tabindex="-1"
                                        aria-disabled="true"><i class="fal fa-arrow-left"></i></a>
                                </li>
                                <li class="page-item"><a class="page-link active" href="#">01</a></li>
                                <li class="page-item"><a class="page-link" href="#">02</a></li>
                                <li class="page-item"><a class="page-link" href="#">03</a></li>
                                <li class="page-item"><a class="page-link" href="#">...</a></li>
                                <li class="page-item"><a class="page-link" href="#">10</a></li>
                                <li class="page-item">
                                    <a class="page-link d-none d-sm-inline-block" href="#"><i
                                            class="fal fa-arrow-right"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div> -->

            </div>

            <!-- <div class="col-lg-4 col-md-6 col-sm-8">
                <app-blogsidebar></app-blogsidebar>
            </div> -->

        </div>
    </div>
</div>
<!--====== BLOG STANDARD PART ENDS ======-->
<app-footer></app-footer>